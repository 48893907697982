<template>
  <div class="loginWrapper">
    <img src="/images/cuhk.png" alt="icon" class="cuhk-logo">
    <img src="/images/science-mobile-icon.png" alt="icon" class="app-icon">
    <h4 class="mb-0 teal-text">Science Mobile</h4>
    <h6 class="grey-text mb-2">Content Editor</h6>
    <div class="notice" v-if="needVerification">Please complete the email verification before login.</div>
    <div class="notice" v-if="error.length > 0 ">
      <div v-for="(err, index) in error" :key="index">{{err}}</div>
    </div>
    <div class="login-box z-depth-3" v-show="type === 'signIn'">
      <div class="login-header cyan darken-3">Sign in</div>
      <div class="login-content" @keyup.enter="signIn">
        <div class="username-input input-field">
          <input type="text" id="username" class="validate" v-model="loginForm.username" required>
          <label for="username">Username</label>
          <span class="helper-text" data-error="*required"></span>
        </div>
        <div class="password-input input-field">
          <input type="password" id="password" class="validate" v-model="loginForm.password" required>
          <label for="password">Password</label>
          <span class="helper-text" data-error="*required"></span>
        </div>
        <div class="btnWrapper">
          <a class="btn waves-light waves-effect orange w-100" @click="signIn">Sign in</a>
          <a class="forgotPwBtn" @click="gotoReset">Forgot your password?</a>
        </div>
      </div>
    </div>
    <div class="reset-box z-depth-3" v-show="type === 'reset'">
      <div class="reset-header cyan darken-3">Reset Password</div>
      <div class="reset-content">
        <div class="email-input input-field">
          <input type="email" id="email" class="validate" v-model="resetForm.email" required>
          <label for="email">Enter your email</label>
          <span class="helper-text" data-error="*invalid email"></span>
        </div>
        <div class="btnWrapper">
          <a class="btn waves-light waves-effect orange darken-3" @click="proceedReset">Reset</a>
          <a class="forgotPwBtn" @click="gotoSignIn">Sign in</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../service/api';
import jwt_decode from "jwt-decode";
export default {
  data() {
    return {
      type: 'signIn',
      loginForm: {
        username: '',
        password: '',
      },
      resetForm: {
        email: '',
      },
      needVerification: false,
      error: [],
    };
  },
  methods: {
    signIn() {
      this.needVerification = false;
      this.verification();
      if (this.error.length > 0) {
        return;
      }
      let self = this;
      api.post('user/auth', this.loginForm).then((res) => {
        let user = jwt_decode(res.data.token);
        window.localStorage.token = res.data.token;
        window.localStorage.user = JSON.stringify(user);
        if (user.role === 'admin' || user.role === 'teacher' || user.role === 'student') {
          window.location.href = '/main.html';
        } else if (user.role === 'inactive') {
          self.needVerification = true;
        } else {
          self.$toasted.error('incorrect username or password.');
        }
      }).catch((err) => {
        if (err.response && err.response.status == 403) {
          self.$toasted.error('incorrect username or password.');
        } else if (err.response && err.response.status == 400) {
          self.$toasted.error('invalid username or password.');
        } else {
          self.$toasted.error('Fail to connect to server.');
        }
      });
    },
    verification() {
      this.error = [];
      if (!this.loginForm.username) {
        this.error.push('Username is required.');
      }
      if (!this.loginForm.password) {
        this.error.push('Password is required.');
      }
    },
    proceedReset() {
      this.emailVerification();
      if (this.error.length > 0) {
        return;
      }
      let self = this;
      api.post('user/reset', this.resetForm).then((res) => {
        self.$toasted.show('Reset password link is sent to your email.');
        self.type = 'signIn';
        self.resetForm.email = '';
      })
      .catch((err) => {
        if (err.response && err.response.status == 400) {
          self.$toasted.error('invalid email.');
        } else if (err.response && err.response.status == 404) {
          self.$toasted.error('Email is not registered.');
        } else {
          self.$toasted.error('Fail to connect to server.');
        }
      });
    },
    emailVerification() {
      this.error = [];
      if (!this.resetForm.email) {
        this.error.push('Email is required.');
      }
      if (document.querySelector('input#email.invalid')) {
        this.error.push('Email is invalid.');
      }
    },
    gotoReset() {
      this.error = [];
      this.needVerification = false;
      this.type = 'reset';
    },
    gotoSignIn() {
      this.error = [];
      this.type = 'signIn';
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  margin-bottom: 1rem;
  color: rgb(109, 109, 109);
  padding: 5px;
  background-color: rgba(153, 153, 153, 0.5);
  border-radius: 3px;
}

.forgotPwBtn {
  display: block;
  text-decoration: underline;
  margin-top: 15px;
  color: #9a9a9a;

  &:hover {
    color: rgb(109, 109, 109);
  }
}

.loginWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 0;
  background: #FFEFBA;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #FFEFBA);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #FFEFBA); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.cuhk-logo {
  position:absolute;
  top:10px;
  left:20px;
  height: 80px;
  width: auto
}

.app-icon {
  width: 150px;
}

.app-title {
  margin: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
}

.login-box, .reset-box {
  border-radius: 5px;
  width: 350px;
  background-color: white
}

.login-header, .reset-header {
  padding: 10px;
  border-radius: 5px 5px 0 0;
  line-height: 2.5rem;
  font-size: 1.3rem;
  text-align: center;
  color: #fff;
}

.login-content, .reset-content {
  padding: 15px;
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    cursor: pointer;
  }
}

.mb-0 {
  margin-bottom: 0px
}

.mb-1 {
    margin-bottom: 10px
}

.mb-2 {
    margin-bottom: 20px
}

.w-100 {
  width:100%
}
</style>

