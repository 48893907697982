import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + 'v1/',
  timeout: 10000,
});

if (window.localStorage.getItem('token')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('token');
}

export default instance;
