import '@materializecss/materialize/dist/css/materialize.min.css';
import '@materializecss/materialize';
import Vue from 'vue';
import Login from './components/Login.vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
  duration: 1000,
  position: 'bottom-left',
});

new Vue({
  render: h => h(Login),
}).$mount('#app-login');
